*,
*::after,
*::before {
  box-sizing: border-box;
}


html, body {
  max-width: 100%;
  overflow-x: hidden;
}

/* Typography */
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body { 
  font-family: 'Roboto Condensed', sans-serif;
}

a {
  text-decoration: none;
}

p {
  font-size: 1.2rem
}

/* Lists */
.list {
  list-style: none;
  padding-left: 0;
  color: var(--color-headings);
  text-decoration: none;
}

/* Color Palette */
:root {
  --color-background: #05aec7;
  --color-primary: #293855;
  --color-dark: #c9c9c9 ;
  --color-light: #f5f5f5;
  --color-body: #656565;
  --color-body-darker: #545454;
  --color-headings: #ffffff;
  --border-radius: 30px;
}

.primary-text {
  color: var(--color-primary);
}

/* Grids */
.grid {
  display: grid;
  justify-items: center;
}

@media screen and (min-width: 821px) {
  .grid--1x3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.center {
  margin: auto;
  width: 50%;
}

@media screen and (max-width: 400px) {
  .center--m {
    margin: auto;
    width: 50%;
  }
}