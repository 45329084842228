.hero {
    height: 80vh;
    background-image: 
    url("https://cdn.pixabay.com/photo/2021/02/22/05/26/employee-6038877_1280.png"),
    linear-gradient(
        #05aec7,
        #05aec7
    );
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.tagline {
    font-family: 'Merriweather', serif;
    position: relative;
    display: inline-block;
    top: 5%;
    left: 5%;
    font-size: 4rem;
    color: white;
    text-transform: uppercase;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 70%;
    border-left: 5px solid var(--color-primary);
   padding: 2rem;
    text-align: left;
}

@media screen and (min-width: 768px) {
    .tagline {
        top: 0;
        font-size: 8rem;
        left: 5%;
    }
}


@media screen and (min-width: 1600px) {
    .tagline {
    
        top: 10%;
    }
}