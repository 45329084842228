/* Navigation */

nav {
  align-items: center;
  background: #05aec7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  nav {
     flex-direction: row;
     padding: 0.25rem 4rem;
  }
}

@media screen and (min-width: 768px) {
  .nav__item {
    padding: 0.5rem 2rem;
  }
}

.nav__item > a {
  color: #fff;
}

.nav__item > a:hover {
  border-bottom: 2px double var(--color-primary);
}

  .nav__list {
    margin: 2rem;
    width: auto;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: bold;
    max-height: 100%;
    opacity: 1;
    transition: 0.1s ease-in-out;
    animation: headShake; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    animation-delay: 1s;
    position: relative;
    top: 25px;
  }

  .nav__item {
    border: 0;
    padding: 0 5px;
    margin-bottom: 1rem;
    letter-spacing: 3px;
  }

  .logo::before {
    content: url("../assets/attitude.png");
    position: relative;
    top: 5px;
    margin: 1rem;
    z-index: -1;
  }

  .robot--o {
    content: url("../assets/attitude-large.png");
    position: relative;
    top: 10px;
  }

  @media screen and (min-width: 768px) {
   
  .robot--o {
    top: -5px;
  }
  }

  .logo {
    font-size:  10px;
    cursor: pointer;
    z-index: 1;
    text-align: center;
  }

  .logo > a {
    color: #fff;
  }

  @media screen and (min-width: 768px) {
    .logo {
      font-size: 25px;
    }

    .nav__list { 
      font-size: 1.4rem;
    }

  }