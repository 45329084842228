.grid--block {
    width: 100%;
    height: 95vh;
    color: #fff;
}
@media screen and (min-width: 1600px) {
    .grid--block {
        width: 100%;
        height: 65vh;
        color: #fff;
    }
}
.virtual--block {
    background-color: #de8f6e;
}
.communication--block {
    background-color: #DBD56E;
}
.content--block {
    background-color: #7D7C84;
}
.grid--block > h4::after {
    content: " ⇁";
    cursor: pointer;
}
.grid--block > h4:hover::after {
    display: inline-block;
    margin: 0 0.5rem;
    animation: rubberBand; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
    animation-iteration-count: 5;
}
.grid--block > h3 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 8rem;
}
.grid--block {
    position: relative;
}

/* Virtual Customer Serivce */
.bot {
    text-align: center;
    font-size: 1.25rem;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    width: 300px;
    height: 50rem;
    margin-left: -25%;
    color: var(--color-body-darker);
    padding: 3rem;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    top: -50px;
}
.grid--block > h4 {
    text-align: left;
    margin-left: 1.5rem;
    font-size: 2rem;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}
.grid--block > h4 > a {
    color: white;
}
.random--user {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #404040;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    font-size: 1.75rem;
    background-color: whitesmoke;
}
.random--user > img {
    border-radius: 100%;
    height: 75px;
    width: 75px;
    position: relative;
    top: -10px;
    margin-right: 2rem;
}
.random--user > h3 {
    position: relative;
    top: -10px;
    margin-left: 1rem;
}
.green--circle {
    height: 20px;
    width: 20px;
    background-color: rgb(0, 236, 0);
    border-radius: 100%;
    position: relative;
    top: 18px;
}
.message--bot {
    text-align: left;
    background: rgb(121,9,111);
    background: linear-gradient(13deg, #8f2dff , #8f2dff);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 30px;
    padding: 0.75rem 2rem;
    color: white;
    max-width: 200px;
}
.message--user {
    text-align: left;
    background: rgb(121,9,111);
    background: linear-gradient(13deg, #ff2dee , #ed28ff);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 5px;
    padding: 0.75rem 2rem;
    color: white;
    max-width: 200px;
    position: relative;
    left: 40px;
}

/* Mass Communication */

.email {
    text-align: left;
    font-size: 1.25rem;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    width: 300px;
    height: 50rem;
    margin-left: -25%;
    color: var(--color-body-darker);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    top: -50px;
    left: 37vw;
}
@media screen and (min-width: 400px) {
    .bot, .email {
        margin-left: 7vw;
    }
    .email {
        left: 0vw;
    }
}
@media screen and (min-width: 1600px) {
    .email {
        top: 1vh;
        left: 4vw;
    }
}
.email--header {
    background-color: 
    #404040;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    padding: 1rem;
    text-align: left;
}
.email--header::after {
    position: relative;
    left: 55%;
    content: "🟡  🟢  🔴";
    font-size: 10px;
}
.email > form  > input{
    border: 0.5px solid gray;
    border-left: none;
    border-right: none;
    border-top: none;
    padding-top: .75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
    outline: none;
    width: 100%;
}
.email > form > textarea {
    resize: none;
    border: none;
    outline: none;
    padding-top: 0.75rem;
    padding-left: 0.75rem;
}
.btn {
    margin:  0.75rem;
    border-radius: 5px;
    background-color: #1b73e8;
    color: #fff;
    border: none;
    padding: 0.75rem 1rem;
    max-width: 55px;
    position: relative;
    bottom: -250px;
}
.btn:hover {
    cursor: pointer;
    filter:brightness(120%);
}

/* Prompt Engineering */
.prompt {
    height: 50rem;
    position: relative;
}
.prompt > img {
    height: 200px;
    border-radius: 100%;
    position: relative;
    left: 30%;
}
.prompt > p {
    font-size: 3rem;
    text-align: center;
}
@media screen and (min-width: 1600px) {
    .bot{
        top: 20%;
        margin-left: 10vw;
    }
    .prompt {
        top: 20%;
    }
    .prompt > img {
        left: 38%;
    }
}

/* Pop-up Site */
.popup, .popup > header > nav {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 300px;
    color: white;
    background-color: #75c3d5;
    flex-direction: row;
}
.popup, .popup > header > nav > p {
    margin-inline: 1rem;
}
.popup, .popup > header > nav:hover {
    cursor: pointer;
}
.popup {
    text-align: left;
    font-size: 1.25rem;
    position: relative;
    background-color:#75c3d5;
    border-radius: 10px;
    width: 300px;
    height: 50rem;
    color: var(--color-body-darker);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    top: -50px;
    left: -15vw;
}
@media screen and (min-width: 385px) {
    .popup {
        top: -50px;
        left: 6.2vw;
    }
}
@media screen and (min-width: 1600px) {
    .popup {
        top: -0;
        left: 10vw;
    }
}
.popup > div > .popup-logo {
    max-height: 90px;

}
.popup-text {
    float: left;
    margin-top: 4rem;
    background-color: #75c3d5;
    padding: 1rem;
    height: 42%;
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}
.popup-text > span > h4 {
    text-align: center;
    line-height: 1.5rem;
}